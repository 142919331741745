import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import './settings.css'
import { useEffect, useState } from 'react';
import { getConfig, updateConfig } from 'services/purecloud';
import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

const Settings = () => {
    const [isBargingEnabled, setBargingEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const StyledCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(4),
        marginTop: theme.spacing(2),
        'width': '100%',
        'box-sizing': 'border-box',
        'box-shadow': '2px 2px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 2px 1px 3px 5px rgba(0, 0, 0, 0.12)'
    }));

    const StyledSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        marginRight: '8px',
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(35, 57, 93)' : 'rgb(35, 57, 93)',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));


    useEffect(() => {
        const getBargingDetails = async () => {
            setIsLoading(true);
            const response = await getConfig();
            setBargingEnabled(response.isBargingEnabled);
            setIsLoading(false);
        }
        return () => {
        }
    }, [])

    const handleChange = async () => {
        setIsLoading(true);
        await updateConfig({ isBargingEnabled: !isBargingEnabled })
        setBargingEnabled(!isBargingEnabled);
        setIsLoading(false);
    }

    return <>
        {!isLoading && <StyledCard >
            <h5>Barging setting</h5>
            <div className='barge-content'>
                <p className='barge-text'>Supervisors must be able to barge into live conversation</p>
                {/* <span><StyledSwitch checked={isBargingEnabled}
                    onChange={handleChange} />{isBargingEnabled ? 'on' : 'off'}</span> */}
                <span><StyledSwitch disabled />{isBargingEnabled ? 'on' : 'off'}</span>
            </div>
        </StyledCard>}
        {isLoading && <Box sx={{ height: '85vh' }}><Spinner /></Box>}
    </>

}


export default Settings;