// Global CSS
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

// Default React 
import React from 'react'
import ReactDOM from 'react-dom/client'

// Application
import ApplicationRouter from './routers/AppRouter'

// Utility
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import store from './redux'

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Noto Sans',
        textTransform: 'none',
      },
    },
  });

const root = ReactDOM.createRoot(
    document.getElementById('root')
);
root.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ApplicationRouter />
            </ThemeProvider>
        </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();