import React from "react";
import { Button } from "../../styles";
import { Status, Icon, SpanDiv } from "../../Index/index-style";

import { useLocation, useNavigate } from "react-router-dom";


const ProductStatus = (props) => {
    const navigate = useNavigate();
    const { status, productStatus, statusIcon, btnIcon, msgContent, eventChange, isStart } = props;
    return (
        <div>
            <Status isSuccess={status}>
                <SpanDiv>{productStatus}</SpanDiv>
                <Icon className={statusIcon}></Icon>
            </Status>
            <p>
                <span>{msgContent}</span>
            </p>
            {status === "success" && btnIcon &&
                <>
                    <Button position="right" onClick={() => eventChange('configuration')}>
                        <SpanDiv>Start</SpanDiv>
                        <i className={`button-icon ${btnIcon}`}></i>
                    </Button>

                </>
            }

            {status === "success" && statusIcon && isStart != true &&
                <>
                    <Button position="right" onClick={() => navigate("/main")}>
                        <SpanDiv>Finish</SpanDiv>
                        <i className={`button-icon ${statusIcon}`}></i>
                    </Button>

                </>
            }
        </div>
    )
}

export default ProductStatus