import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';

export const StyledButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-textPrimary': {
        'padding': '5px 10px',
        'fontSize': '11px',
        'background': '#34435e',
        'border': '0px solid',
        'color': 'white',
        'letterSpacing': '0.5px'
    },
    '&:hover': {
        'backgroundColor': '#3742a2'
    },
    '&:disabled': {
        'color': 'rgba(0, 0, 0, 0.26)',
        'backgroundColor': 'rgba(0, 0, 0, 0.12)'
    }
}));

export const SecondaryButton = styled(StyledButton)(({ theme }) => ({
    '&.MuiButton-textPrimary': {
        'background': '#e35744',
        'color': 'white',
        'letterSpacing': '1px'
    },
    '&:hover': {
        'backgroundColor': '#fe624d'
    },
    '&:disabled': {
        'color': 'rgba(0, 0, 0, 0.26)',
        'backgroundColor': 'rgba(0, 0, 0, 0.12)'
    }
}));
export const QueueContainer = styled(TableContainer)(({ theme }) => ({
    
    '&::-webkit-scrollbar': {
        width: '0.8rem'
      },
      '&::-webkit-scrollbar-track': {
        outline: '1px solid lightgrey' ,
        borderRadius: '0.3rem'  
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ced4da',
        // outline: '1px solid slategrey',
        borderRadius: '0.3rem'  ,
        height: '5rem'
  }
    
}));