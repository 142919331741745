import { createSlice } from "@reduxjs/toolkit";
import { addConversationDetails, makeConversationInactive, storeMessagesToCloud, updateAgentsNames } from "./agentActions";
import { getScore, weight } from "services/scoreCalculator";

const initialState = {
  isCreated: false,
  isDeleted: false,
  loading: true,
  messages: [],
  prevMsgsSignedUrl: '',
  signedUrl: '',
  messagesTotalScore: 0,
  messagesCount: 0,
  analysisScore: 50,
  prevAnalysisScore: 50,
  agentsNameArr:[],
  details: {},
  error: ''
};
//analysisScore -  is the average score of all messages and converting it to range 0-100
//messagesTotalScore - is the sum of score of all messages
// score of each message ranges between -5 to 5 (decimal)

const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    addMessages: (state, action) => {
      state.messages = state.messages.concat(action.payload.message);
      state.messagesCount += weight[Math.round(action?.payload?.message?.sentimentScore)];
      state.prevAnalysisScore = state.analysisScore;
      state.messagesTotalScore += (weight[Math.round(action?.payload?.message?.sentimentScore)] * parseFloat(action?.payload?.message?.sentimentScore));
      if (state.messagesCount > 0) {
        state.analysisScore = getScore(state.messagesTotalScore / state.messagesCount);
      }
    },
    addConversationId: (state, action) => {
      state.conversationId = action.payload.conversationId;
    },
    createConversationInterval: (state, action) => {
      state.conversationInterval = action?.payload?.interval;
    },
    updateLoadingStatus: (state, action) => {
      state.loading = action?.payload?.loading;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeMessagesToCloud.fulfilled, (state, action) => {
        state.loading = false;
        state.signedUrl = action.payload.signedUrl;
      }).addCase(storeMessagesToCloud.rejected, (state, action) => {
        state.loading = false;
        state.signedUrl = '';
        state.error = action.payload;
      })
      .addCase(addConversationDetails.fulfilled, (state, action) => {
        if(action.payload.isChecked) {
          state.loading = false;
          state.details = action.payload?.details;
          state.isCreated = action.payload.isCreated;
          state.messages = action.payload?.previousMessages?.length > 0 ? action.payload?.previousMessages.concat(state.messages) : state.messages;
          state.messagesTotalScore += parseFloat(action.payload?.messagesTotalScore);
          state.agentsNameArr = action.payload?.agentsNameArr;
          state.agentsIds = action.payload?.agentsIds;
          state.messagesCount = action.payload?.messagesCount + state.messagesCount;
          if (state.messagesCount > 0) {
            state.analysisScore = getScore(state.messagesTotalScore / state.messagesCount);
          }
          state.prevMsgsSignedUrl = action.payload.prevMsgsSignedUrl;
          state.prevAnalysisScore = action.payload.prevAnalysisScore || state.prevAnalysisScore ;
        }
      })
      .addCase(addConversationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAgentsNames.fulfilled, (state, action) => {
        state.agentsNameArr = action.payload?.agentsNameArr;
        state.agentsIds = action.payload?.agentsIds;
      })
      .addCase(updateAgentsNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(makeConversationInactive.fulfilled, (state, action) => {
        state.status = action.payload;
        state.isDeleted = true;
      })
      .addCase(makeConversationInactive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

  },
},
);


export const { addMessages, addConversationId, createConversationInterval, updateLoadingStatus } = agentSlice.actions;

export default agentSlice.reducer;