import { useEffect } from 'react';
import './chat.css'

const Chat = ({ messages, isScrollEnabled }) => {
    

    useEffect(() => {
        if(isScrollEnabled) {
            scrollToEnd();
        }
    }, [messages])

    const scrollToEnd = () => {
        const elementToScroll = document.getElementById('rtt-chat-box-agent-view');
        if (elementToScroll) {
            elementToScroll.scrollTop = elementToScroll.scrollHeight;
        }
    };

    const getClassByMsgScore = (score) => {
        if(score < 0) {
            return 'red';
        } else if(score >= 0 && score < 1) {
            return 'yellow';
        } else if(score >= 1 && score <=5){
            return 'green'
        }
    }

    return (
        <div className="chat-box" id="rtt-chat-box-agent-view">
            { messages.map((message, index) => (
                <div className={'messageContainer' + message.purpose}>
                    {(index === 0 || message.purpose != messages[index-1]?.purpose) && <p className={'messageLabel'}>{message.purpose === 'customer'? 'Customer': 'Agent'}</p>}
                    <p key={message.utteranceId
                    } className={message.purpose + ' ' + `${getClassByMsgScore(message?.sentimentScore)}`}>{message.text}</p>
                </div>
            ))}
        </div>
    );
}

export default Chat;
