import { configureStore } from "@reduxjs/toolkit";
import agentReducer from './agent/agentSlice'

const store = configureStore({
  reducer: {
    agent: agentReducer,
    devTools: true,
  },
});


// export type RootState = ReturnType<typeof store.getState>

// export type AppDispatch = typeof store.dispatch
// export const useReduxDispatch = (): AppDispatch => useDispatch<AppDispatch>()
// export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector

export default store;