import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { accordionHeader, imageBinder, listBinder, contentBinder, documentationModel, Accordion, AccordionDetails, stepsBinder } from './common';
import { Box } from '@mui/material';
import userGuide from './UsersGuide_en.json'
import { StyledButton } from 'common/common';
import { getStorageItem } from 'services/applicationStorage';

const HelpPage = () => {
    const [markdown, setMarkdown] = useState(userGuide);
    const [expanded, setExpanded] = useState('parentPanel0');
    const [expandedChildPanel, setExpandedChildPanel] = useState('childPanel0');
    const [expandedScChildPanel, setScExpandedChildPanel] = useState('innerChildPanel0');

    // useEffect(() => {
    //     const filePath = "./UsersGuide_en.json";
    //     setMarkdown(filePath);
    //     fetch(filePath)
    //         .then((response) => response.text())
    //         .then((text) => {
    //             const parsedData = JSON.parse(text);
    //             if (parsedData) {
    //                 setMarkdown(parsedData);
    //             }
    //         })
    //         .catch((error) => console.log(error));
    // }, []);


    const handleChange = (panel, pannelType) => (event, newExpanded) => {
        pannelType === "parentPanel" ? setExpanded(newExpanded ? panel : false) : pannelType === "childPanel" ? setExpandedChildPanel(newExpanded ? panel : false) : setScExpandedChildPanel(newExpanded ? panel : false);
    };

    const downloadFile = (name) => {
        
        const pdfUrl = "/resources/UserGuide/"+ name +".script";
        fetch(pdfUrl)
        .then(response => response.text())
        .then(data => {
            let modifiedData = data.replace('usw2.pure.cloud', getStorageItem('purecloud-csp-env', true, sessionStorage));

            // Create a blob with the modified data
            let blob = new Blob([modifiedData], { type: 'text/plain' });

            // Create a link element
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            // const link = document.createElement("a");
            // link.href = pdfUrl;
            link.download = name + ".script"; 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    return (
        <Box sx={{ marginTop: 2 }}>
            <Typography sx={{ marginBottom: 2 }}>
                To empower agents and supervisors with real-time transcription and sentiment analysis capabilities, ensuring proactive response during live interactions. This guide will walk users through the functionalities of the Real-Time Transcription application, enabling them to efficiently gauge sentiments, monitor live transcripts, manage flagged interactions, and enhance overall customer experience. By understanding and leveraging these tools, agents and supervisors can drive effective communication and make informed decisions during interactions.
            </Typography>
            {markdown?.map((e, i) => {
                return (
                    <Accordion expanded={expanded === `parentPanel${i}`} onChange={handleChange(`parentPanel${i}`, "parentPanel")} sx={{ marginRight: 2 }}>
                        {accordionHeader(i, e)}
                        <AccordionDetails>
                            {e?.content && e?.content.map((c) => {
                                return (
                                    <>
                                        <Typography sx={{ m: 2 }} >{c}</Typography>
                                        {e?.childContent && e?.childContent.map((ch, i) => {
                                            return (
                                                <Accordion expanded={expandedChildPanel === `childPanel${i}`} onChange={handleChange(`childPanel${i}`, "childPanel")}>
                                                    {accordionHeader(i, ch)}
                                                    <AccordionDetails>
                                                        {contentBinder(ch.content)}
                                                        {imageBinder(ch.imagePath)}
                                                        {ch?.content1 && contentBinder(ch.content1)}
                                                        {ch?.imagePath1 && imageBinder(ch.imagePath1)}
                                                        {ch?.secondChildContent && ch?.secondChildContent.map((sc, i) => {
                                                            return (
                                                                <Accordion expanded={expandedScChildPanel === `innerChildPanel${i}`} onChange={handleChange(`innerChildPanel${i}`, "innerChildPanel")}>
                                                                    {accordionHeader(i, sc)}
                                                                    <AccordionDetails>
                                                                        {contentBinder(sc.content)}
                                                                        {sc?.content1 && contentBinder(sc.content1)}
                                                                        {sc?.imagePath1 && imageBinder(sc.imagePath1)}
                                                                        {listBinder(sc)}
                                                                        {imageBinder(sc.imagePath)}
                                                                    </AccordionDetails>
                                                                </Accordion >
                                                            )
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion >
                                            )
                                        })}
                                    </>
                                )
                            })}
                            {listBinder(e)}
                            {e?.imagePath && imageBinder(e.imagePath)}
                            {e?.steps && stepsBinder(e.steps)}
                            {e?.button1 && <StyledButton onClick={() => downloadFile(e.button1)}>Download script</StyledButton>}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Box >
    );
}

export default HelpPage;
