// actions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InvalidToken } from 'common/Constants';
import { agentRole } from 'config';
import { getStorageItem } from 'services/applicationStorage';

const platformClient = window.require('platformClient');

const conversationApi = new platformClient.ConversationsApi();
import { createConversationDetails, createUploadUrl, getConversationMessages, getSignedUrl, makeConvInactive, updateAnalysisScore, uploadMessages, updateAgentNames } from 'services/purecloud';


// Define a createPost action
export const storeMessagesToCloud = createAsyncThunk('agent/storeMessagesToCloud', async (_, { getState, rejectWithValue }) => {
    const currentState = getState();
    const conversationId = currentState.agent?.conversationId;
    try {
        const isConvCreated = currentState?.agent?.isCreated;
        const isDeleted = getState()?.agent?.isDeleted;
        let signedUrl = currentState?.agent?.signedUrl;

        if (isConvCreated && signedUrl === '') {
            const response = await createUploadUrl({
                conversationId: conversationId
            });
            signedUrl = response.signedUrl
        }

        if (isConvCreated && signedUrl !== '' && !isDeleted) {
            // updating analysis score to dynamodb
            await updateAnalysisScore({
                conversationId: conversationId,
                analysisScore: currentState.agent.analysisScore
            });
            // uploading all messages  to s3( we cannot partially update , so we are fully overriding messages in s3)
            await uploadMessages(signedUrl, {
                conversationId: conversationId,
                ...currentState.agent.details,
                messages: currentState.agent.messages,
                messagesCount: currentState.agent.messagesCount,
                messagesTotalScore: currentState.agent.messagesTotalScore,
                analysisScore: currentState.agent.analysisScore,
                prevAnalysisScore: currentState.agent.prevAnalysisScore
            });
        }
        return { signedUrl: signedUrl };
    } catch (error) {
        console.error(error);
        if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
            return rejectWithValue({ status: 401 });
        }
        return rejectWithValue({});
    }
});

export const addConversationDetails = createAsyncThunk('agent/addConversationDetails', async (payload, { getState, rejectWithValue }) => {
    const currentState = getState();
    const conversationId = currentState?.agent?.conversationId;
    const isCreated = currentState?.agent?.isCreated;
    const interval = currentState?.agent?.conversationInterval;
    const messages = currentState.agent.messages;
    const prevMsgsSignedUrl = currentState.agent.prevMsgsSignedUrl;
    console.log("addConversationDetails 1");
    try {
        if (!isCreated && conversationId) {
            const token = getStorageItem('purecloud-csp-token', true, sessionStorage);
            platformClient.ApiClient.instance.setAccessToken(token);
            platformClient.ApiClient.instance.setEnvironment(getStorageItem('purecloud-csp-env', true, sessionStorage));
            console.log("addConversationDetails 2");
            const conversationResult = await conversationApi.getConversation(conversationId);

            const agents = conversationResult?.participants?.reverse()?.filter(item => item.purpose === 'agent');
            const [agentsIds, agentsNames] = removeDuplicateAgents(agents);

            const currentAgent = agents.filter(item => item?.userId === getStorageItem('userId', true, sessionStorage))?.[0];

            let previousMessages = [];
            let messagesTotalScore = 0;
            let messagesCount = 0;
            let prevAnalysisScore = 0;
            let getMessageResponse = {};
            console.log("addConversationDetails 3");
            if (currentAgent?.calls[0]?.state?.toLowerCase() === 'connected') {
                console.log("addConversationDetails 4");
                if (agents?.length > 1 && prevMsgsSignedUrl === '') {
                    try {
                        getMessageResponse = await getSignedUrl(conversationId);
                        const messagesResponse = await getConversationMessages(getMessageResponse.signedUrl)
                        previousMessages = messagesResponse.messages;
                        messagesTotalScore = messagesResponse?.messagesTotalScore || 0;
                        messagesCount = messagesResponse?.messagesCount || 0;
                        prevAnalysisScore = messagesResponse?.prevAnalysisScore ?? 0;
                    } catch (error) {
                        console.error(error);
                        if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
                            return rejectWithValue({ status: 401 });
                        }
                        
                    }
                }
                if (previousMessages == undefined || previousMessages == null) {
                    previousMessages = [];
                }
                previousMessages = previousMessages.concat(messages);

                let request = createRequest(conversationResult, conversationId, currentAgent, agentsNames);
                
                
                // const queue = await routingApi.getRoutingQueue(request.queueId);
                
                // request.divisionId = queue.division.id;
                await createConversation(request, rejectWithValue)
                clearInterval(interval);
                return {
                    conversationId: conversationId, ...request, isCreated: true, agentsNameArr: agentsNames,isChecked: true,
                    agentsIds: agentsIds, previousMessages, prevAnalysisScore, messagesTotalScore, messagesCount, prevMsgsSignedUrl: getMessageResponse.signedUrl
                };
            } else {
                return {
                    isCreated: false, previousMessages: [],isChecked: false,
                    messagesTotalScore: 0, messagesCount: 0, prevMsgsSignedUrl: '',
                    prevAnalysisScore: 0, agentsNameArr: [], agentsIds: []
                };
            }
        }
    } catch (error) {
        console.error(error);
        if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
            return rejectWithValue({ status: 401 , error: error});
        }
        return rejectWithValue(error);
    }
   return {isChecked: false};
});

export const makeConversationInactive = createAsyncThunk('agent/makeConversationInactive', async (payload, { getState, rejectWithValue }) => {
    const currentState = getState();
    const conversationId = getState()?.agent?.conversationId;
    const signedUrl = currentState?.agent?.signedUrl;
    const isDeleted = currentState?.agent?.isDeleted;
    const connectedAgents = payload?.connectedAgents;
    try {
        if (!isDeleted) {
            await makeConvInactive({ conversationId: conversationId, connectedAgents: connectedAgents, agentRole: agentRole });
            if (signedUrl && signedUrl !== '') {
                await uploadMessages(signedUrl, {
                    conversationId: conversationId,
                    ...currentState.agent.details,
                    messages: currentState.agent.messages,
                    analysisScore: currentState.agent.analysisScore
                });
            }
        }
        return { "status": "sessionEnded" };
    } catch (error) {
        console.error(error);
        if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
            return rejectWithValue({ status: 401 });
        }
        return rejectWithValue(error);
    }
});

// updates the api with all agent names involved in interaction
export const updateAgentsNames = createAsyncThunk('agent/updateAgentsNames', async (payload, { getState, rejectWithValue }) => {
    const currentState = getState();
    const conversationId = currentState?.agent?.conversationId;
    const prevAgentsIds = currentState?.agent?.agentsIds;
    const prevAgentsNameArr = currentState?.agent?.agentsNameArr;
    const isConvCreated = currentState?.agent?.isCreated;
    const agentsIds = payload;
        console.log("updateAgentsNames")

    try {
        // updates only if conversation is created
        if (isConvCreated && agentsIds?.length !== prevAgentsIds?.length) {
            const conversationResult = await conversationApi.getConversation(conversationId);

            const agents = conversationResult?.participants?.reverse()?.filter(item => item.purpose === 'agent');
            const [agentsIds, agentsNames] = removeDuplicateAgents(agents);
            updateAgentNames({
                conversations: [{
                    conversationId: conversationId,
                    agentsName: agentsNames.join(", ")
                }]
            })
            return { agentsNameArr: agentsNames, agentsIds: agentsIds };
        } else {
            return { agentsNameArr: prevAgentsNameArr, agentsIds: prevAgentsIds };
        }
    } catch (error) {
        console.error(error);

        if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
            //console.log(config.appBaseUrl+'?conversationId='+ conversationId);
            sessionStorage.setItem('conversationId', conversationId)
            return rejectWithValue({ status: 401 });
        }
        return rejectWithValue({});;
    }
});

function createRequest(conversationResult, conversationId, agent, agentsName) {
    const customer = conversationResult?.participants?.filter(item => item.purpose === 'customer')?.[0];

   
    return {
        id: conversationId,
        agentsName: agentsName.join(', '),
        queueId: agent?.queueId,
        startTime: agent?.connectedTime,
        callDirection: agent?.calls[0]?.direction,
        ani: customer?.ani?.substring(4),
        dnis: customer?.dnis?.substring(4),
        analysisScore: 50,
        isFlagged: "false",
        isActive: "true",
        customerId: customer?.participantId,
    };
}

function removeDuplicateAgents(agents) {
    let agentsIds = new Set();
    let agentsNames = [];
    agents.forEach(item => {
        if (!agentsIds.has(item?.userId)) {
            agentsIds.add(item?.userId);
            agentsNames.push(item?.name);
        }
    });

    agentsIds = Array.from(agentsIds);
    return [agentsIds, agentsNames];
}

async function createConversation(request, rejectWithValue) {
   
        await createConversationDetails(request);
    
}

