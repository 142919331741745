import React from "react";
import { Progress } from "./index-style"


const ProgressBar = (props) => {
    let start, config, install, summary = "";
    switch (props.tabName) {
        case "start": start = "active current"; break;
        case "config": config = "active current"; start = "active"; break;
        case "install": install = "active current"; start = "active"; config = "active"; break;
        case "summary": summary = "active current"; start = "active"; config = "active"; install = "active"; break;
        case "help": summary = "active"; start = "active"; config = "active"; install = "active"; break;
        default: ""
    }

    return (
        <Progress>
            <li className={start}>
            {start == "active current" ?  <i class="fa-solid fa-check"></i> : <i>1</i>}
                <span className="txt-start">Start</span>
            </li>
            {/* <li className={config}>
                <span className="txt-install">Configuration</span>
            </li> */}
            <li className={install}>
            {install == "active current" ?  <i class="fa-solid fa-check"></i> : <i>2</i>}
            
                <span className="txt-install">Install</span>
            </li>
            <li className={summary}>
            {summary == "active current" ?  <i class="fa-solid fa-check"></i> : <i>3</i>}
           
                <span className="txt-summary">Summary</span>
            </li>
        </Progress>
    )
}

export default ProgressBar;