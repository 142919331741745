import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Checkbox, InputAdornment, Tooltip, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import TablePagination from '@mui/material/TablePagination';
import { StyledSearch, StyledTableCell, StyledTableRow } from '../supervisor/common';
import { getStorageItem } from 'services/applicationStorage';

import { getFlaggedConversationDetails, updateFlagStatus } from 'services/purecloud';
import TranscriptModal from 'components/transcriptModal/transcriptModal';
import './flaggedAgents.css'
import { formatDate, formatDateAndTime, getSentimentIcon } from 'services/utils';
import { SecondaryButton, StyledButton } from 'common/common';
import Spinner from 'components/Spinner/Spinner';
import MessageIcon from '@mui/icons-material/Message';
import SearchIcon from '@mui/icons-material/Search';
import ModalConfirmation from 'components/modalConfirmation/modalConfirmation';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router';
import { InvalidToken } from 'common/Constants';

const FlaggedAgentView = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isTranscriptOpen, setTranscriptOpen] = useState(false);
    const [isDeleteMdlOpen, setDeleteMdlOpen] = useState(false);
    const [flaggedAgents, setFlaggedAgents] = useState([])
    const [filteredAgents, setFilteredAgents] = useState([])
    const [conversationId, setConversationId] = useState(null);
    const [isFlagged, setIsFlagged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedDate, setSelecetedDate] = useState(null);
    const [selectedConversations, setSelectedConversations] = React.useState([]);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setSelectedConversations([]);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setSelectedConversations([])
    };

    useEffect(() => {
        const getFlaggedList = async () => {
            try {

                const userId = getStorageItem('userId', true, sessionStorage);
                const flaggedAgentsResult = await getFlaggedConversationDetails(userId);
                const agents = flaggedAgentsResult?.data?.map(item => {
                    return {
                        ...item,
                        startDate: formatDate(item.startTime)
                    }
                });
                setFlaggedAgents(agents);
                setFilteredAgents(agents);
                setIsLoading(false);
            } catch (e) {
                console.error(e);
                // if (e.message === InvalidToken) {
                //     navigate('/login');
                // }
            }
        }
        setIsLoading(true);
        getFlaggedList();
    }, [])

    const onCloseTranscript = () => {
        setTranscriptOpen(false);
        setConversationId(null);
    }

    const onOpenTranscript = (conversationId, isFlagged) => {
        setIsFlagged(isFlagged);
        setConversationId(conversationId);
        setTranscriptOpen(true);
    }

    const deleteConversations = async () => {
        try {
            setFilteredAgents(filteredAgents.filter(item => !selectedConversations.includes(item.id)));
            setFlaggedAgents(flaggedAgents.filter(item => !selectedConversations.includes(item.id)));
            setSelectedConversations([]);
            setDeleteMdlOpen(false);
            setPage(0);
            await updateFlagStatus(
                {
                    "conversations": selectedConversations.map(id => {
                        return {
                            "conversationId": id,
                            "isFlagged": "false"
                        }
                    }),
                    "userId": getStorageItem('userId', true, sessionStorage)
                });
        } catch (e) {
            console.error(e);
            if (e.message === InvalidToken) {
                navigate('/login');
            }
        }
    }

    const requestSearch = (searchString) => {
        setSearchValue(searchString.toLowerCase().trim());
        setPage(0);
    };

    useEffect(() => {
        let resultList = []
        resultList = flaggedAgents?.filter((item) => {
            if (item.agentsName?.toLowerCase()?.includes(searchValue) ||
                item.ani?.includes(searchValue) ||
                item.dnis?.includes(searchValue) ||
                item.callDirection?.toLowerCase()?.includes(searchValue) ||
                item.analysisScore == searchValue
            ) {
                return true;
            }
            return false;
        });
        if (selectedDate) {
            const date = selectedDate.format("YYYY-MM-DD");
            resultList = resultList?.filter(item => item.startDate === date);
        }
        setFilteredAgents(resultList);

    }, [searchValue, selectedDate])

    const isSelected = (id) => {
        return selectedConversations.indexOf(id) !== -1;
    }

    const onBulkDelete = () => {
        setDeleteMdlOpen(true);
    }

    const onSingleDelete = (conversationId) => {
        setSelectedConversations([conversationId]);
        setDeleteMdlOpen(true);
    }

    const onDeleteCancel = () => {
        setDeleteMdlOpen(false);
        setSelectedConversations([])
    }
    const onAllCheckBoxClick = (event) => {
        if (event.target.checked) {
            const newSelected = filteredAgents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => item.id);
            setSelectedConversations(newSelected);
            return;
        }
        setSelectedConversations([]);
    };

    const OnCheckBoxClick = (id) => {
        const selectedIndex = selectedConversations.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedConversations, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedConversations.slice(1));
        } else if (selectedIndex === selectedConversations.length - 1) {
            newSelected = newSelected.concat(selectedConversations.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedConversations.slice(0, selectedIndex),
                selectedConversations.slice(selectedIndex + 1),
            );
        }

        setSelectedConversations(newSelected);
    };

    const filterByDate = (dateObj) => {
        setSelecetedDate(dateObj);
        setPage(0);
    }

    const clearAllFilters = () => {
        setSelecetedDate(null);
        setSearchValue('');
        setPage(0);
    }
    return <>
        {!isLoading && <Box >
            <Box sx={{ display: 'flex', gap: '10px', margin: '0.5rem', justifyContent: 'flex-end', alignItems: 'center' }}>
                <DatePicker
                    label="Filter By Date"
                    value={selectedDate}
                    onChange={(newValue) => filterByDate(newValue)}
                    format="DD/MM/YYYY"
                    slotProps={{
                        textField: { size: 'small' },
                        field: { clearable: true },
                    }} />
                <StyledSearch id="outlined-basic" label="Search" variant="outlined" value={searchValue}
                    onChange={(e) => requestSearch(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                />
                <StyledButton sx={{ width: '88px' }} onClick={clearAllFilters}>Clear</StyledButton>
                {selectedConversations.length !== 0 && <SecondaryButton onClick={onBulkDelete}>Delete Selected</SecondaryButton>}
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {isTranscriptOpen && <TranscriptModal open={isTranscriptOpen} isFlaggedView={isFlagged}
                    onClose={onCloseTranscript} conversation={{ conversationId: conversationId, isFlagged: true }}></TranscriptModal>}
                <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align='center'>
                                    <Checkbox
                                        color="primary"
                                        indeterminate={selectedConversations.length > 0 && selectedConversations.length < filteredAgents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.length}
                                        checked={filteredAgents?.length > 0 && selectedConversations.length === filteredAgents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.length}
                                        onChange={onAllCheckBoxClick}
                                        inputProps={{
                                            'aria-label': 'select all Conversations',
                                        }}
                                        sx={{
                                            color: 'black',
                                            '&.Mui-checked': {
                                                color: 'black',
                                            },
                                            padding: 0
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">Agents</StyledTableCell>
                                <StyledTableCell align="center">Caller's Number</StyledTableCell>
                                {/* <StyledTableCell align="center">ANI</StyledTableCell> */}
                                <StyledTableCell align="center">Start Time</StyledTableCell>
                                <StyledTableCell align="center">Direction</StyledTableCell>
                                <StyledTableCell align="center">Sentiment</StyledTableCell>
                                <StyledTableCell align="center">Transcript</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredAgents?.length > 0 ? filteredAgents
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).
                                map((row) => {
                                    const isItemSelected = isSelected(row.id);

                                    return (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell align="center" ><Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onChange={() => OnCheckBoxClick(row.id)}
                                                sx={{
                                                    color: '#34435e',
                                                    '&.Mui-checked': {
                                                        color: '#34435e',
                                                    },
                                                }}
                                            /></StyledTableCell>
                                            <StyledTableCell align="center">{row.agentsName}</StyledTableCell>
                                            <StyledTableCell align="center">{row.callDirection == 'inbound' ? row.ani :row.dnis}</StyledTableCell>
                                            {/* <StyledTableCell align="center">{row.ani}</StyledTableCell> */}
                                            <StyledTableCell align="center">{formatDateAndTime(row.startTime)}</StyledTableCell>
                                            <StyledTableCell align="center">{row.callDirection}</StyledTableCell>
                                            <StyledTableCell align="center" >
                                               <Box sx={{ display: 'flex', justifyContent: 'center'}}> {getSentimentIcon(row.analysisScore)}</Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="center"><Tooltip title="Transcript"><MessageIcon onClick={() => { onOpenTranscript(row.id, row.isFlagged) }} sx={{ color: '#23395d', cursor: 'pointer' }} /></Tooltip></StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Delete sx={{ cursor: 'pointer' }} onClick={() => onSingleDelete(row.id)} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                }
                                ) :
                                <StyledTableRow><StyledTableCell colSpan={8}>
                                    <Typography sx={{ textAlign: 'center' }}>No Agents were Flagged</Typography>
                                </StyledTableCell></StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredAgents?.length ? filteredAgents.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box >}
        {isLoading && <Box sx={{ height: '85vh' }}><Spinner /></Box>}
        <ModalConfirmation open={isDeleteMdlOpen} deleteConversations={deleteConversations} onCancel={onDeleteCancel} />
    </>

}

export default FlaggedAgentView;
