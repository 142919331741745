const getHeaders = () => {
    const envFromSession = sessionStorage.getItem('purecloud-csp-env');
    const envFromSessionSanitized = typeof (envFromSession) === 'string' && envFromSession.trim().length > 0 ? envFromSession.trim() : '';
    const envToken = sessionStorage.getItem("purecloud-csp-token");
    const envTokenSanitized = typeof (envToken) === 'string' && envToken.trim().length > 0 ? envToken.trim() : '';

    return {
        headers:
        {
            'token': envTokenSanitized.replaceAll('"', ''),
            'env': envFromSessionSanitized.replaceAll('"', ''),
            'tokensource': 'purecloud',
        }
    }
}

export {
    getHeaders
}