import styled from "styled-components";

export const Status = styled.p`
    color: ${props => props.isSuccess === "success" ? "#0baf4a" : "#f54141e3"};
`
export const Icon = styled.i`
    font-size: 2em;
    vertical-align: middle;
    margin-left: 0.5rem;
`
export const SpanDiv = styled.span`
    margin-right: 0.5rem;
`
