import config from "config";
import utils, { updateQeueus } from "./utils";
import {  getStorageItem } from 'services/applicationStorage';


const platformClient = window.require("platformClient");
const env = getStorageItem('purecloud-csp-env', true, sessionStorage);
const token = getStorageItem('purecloud-csp-token', true, sessionStorage);
// const client = platformClient.ApiClient.instance;

export const fetchWrapper = async (url, init, token) => {
    const response = await fetch(url, {
        ...init,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    });

    let correlationId
    for (let pair of response.headers.entries()) {
        if (pair[0] === 'inin-correlation-id') {
            correlationId = pair[1]
            break
        }
    }

    const json = await response.json()
    return response.ok
        ? { ...json, correlationId }
        : Promise.reject({ ...json, correlationId })
}

export const isLoggedIn = () => {
    console.log('getting token', sessionStorage.getItem('purecloud-csp-token'));
    return sessionStorage.getItem('purecloud-csp-token') !== null
}

export const getOrganization = async (environment, token) => {
    console.log(`getOrganization.env:${environment}, token:${token}`)
    const url = `https://api.${environment}/api/v2/organizations/me`;
    return await fetchWrapper(url, {
        method: 'GET'
    }, token);
}

export const getQueuesDivision = async (divisions, token, env, response = null, pageNumber = 1) => {
    let apiInstance = new platformClient.RoutingApi();
    platformClient.ApiClient.instance.setAccessToken(token);
    platformClient.ApiClient.instance.setEnvironment(env);
    let resultResponse = response || []

    let options = {
        pageNumber: pageNumber,
        pageSize: 300,
        divisionId: divisions
    }

    await apiInstance.getRoutingQueues(options)
        .then(async (data) => {
            // console.debug(data);
            if (data.entities.length > 0) {
                resultResponse.push.apply(resultResponse, data.entities);
                if (options.pageNumber < data.pageCount) {
                    return await getQueuesDivision(divisions, token, env, resultResponse, ++pageNumber);
                } else {
                    return resultResponse;
                }
            } else {
                return resultResponse;
            }
        })
        .catch(async function (err) {
            console.error(err);
            if (err.status == 429) {
                let retrySeconds = err.headers ? err.headers["retry-after"] ? err.headers["retry-after"] : 2 : 2
                console.log(`Waiting ${retrySeconds} seconds before reaching to Genesys Cloud`)
                await utils.sleep(parseInt(retrySeconds) * 1000);
                await getQueuesDivision(divisions, token, env, resultResponse, pageNumber);
                return resultResponse;
            } else if (err.status == 401) {
               throw err;
            } else {
                throw err;
            }
        });
        updateQeueus(resultResponse);

    
}

export const getHeaders = () => {
    return {
            'token': getStorageItem('purecloud-csp-token', true, sessionStorage),
            'env': getStorageItem('purecloud-csp-env', true, sessionStorage)
        }
}

export const fetchUtils = async (url, init,) => {
    
    const response = await fetch(url, {
        ...init,
        headers: getHeaders(),
    });

    const json = await response.json()
    if(response.ok) {
        return json;
    } else {
        throw {status: response.status, ...json}
    }
}

export const getAllConversationDetails = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}conversationDetails/getAllByQueueId`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export const updateFlagStatus =  async (bodyObject) => {
    return await fetchUtils(`${config.backendEndpoint}conversationDetails/updateFlagDetail`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    })
}

export const getFlaggedConversationDetails = (userId) => {
    return fetchUtils(`${config.backendEndpoint}conversationDetails/getFlaggedConversations?userId=${userId}`, {
        method: 'GET',
    });
}

export const getSignedUrl = (conversationId) => {
    return fetchUtils(`${config.backendEndpoint}messages/getMessages?conversationId=${conversationId}`, {
        method: 'GET',
    });
}

export const getConversationMessages = (signedUrl) => {
    return fetch(signedUrl).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.text().then(errorMessage => {
            throw new Error(errorMessage);
          });
        }
      })
}
export const getConfig = () => {
    return fetchUtils(`${config.backendEndpoint}config/getConfig`, {
        method: 'GET',
    });
}

export const updateConfig = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}config/updateConfig`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export const createUploadUrl = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}messages/createOrUpdate`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export const updateAnalysisScore = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}conversationDetails/updateAnalysisScore`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export const updateAgentNames = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}conversationDetails/updateAgentsNames`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export const uploadMessages = (uploadUrl, bodyObject) => {
    return fetch(uploadUrl, {
        method: 'PUT',
        body: JSON.stringify(bodyObject),
        headers: {
            'Content-Type': 'application/json',
          }
    });
}

export const createConversationDetails = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}conversationDetails/create`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export const makeConvInactive = (bodyObject) => {
    return fetchUtils(`${config.backendEndpoint}conversationDetails/makeConversationInactive`, {
        method: 'POST',
        body: JSON.stringify(bodyObject)
    });
}

export default {
    isLoggedIn,
    fetchWrapper,
    getOrganization,
    getQueuesDivision,
    getAllConversationDetails,
    getFlaggedConversationDetails,
    getConversationMessages: getSignedUrl
}
