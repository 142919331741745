
// const scoreMap = {
//     '-10': 5,
//     '-9': 10,
//     '-8': 15,
//     '-7': 20,
//     '-6': 25,
//     '-5': 30,
//     '-4': 35,
//     '-3': 40, 
//     '-2': 45,
//     '-1': 50,
//     '0': 50,
//     '1': 55,
//     '2': 60,
//     '3': 65,
//     '4': 70,
//     '5': 75,
//     '6': 80,
//     '7': 85,
//     '8': 90,
//     '9': 95,
//     '10': 100,
// }
export const weight = {
    '-5' : 1, 
    '-4' : 1, 
    '-3' : 1, 
    '-2' : 1, 
    '-1' : 1, 
    '0' : 2, 
    '1' : 3, 
    '2' : 3, 
    '3' : 3, 
    '4' : 3, 
    '5' : 3
}

const scoreMap = {
    '-5': 0,
    '-4': 10,
    '-3': 20,
    '-2': 30,
    '-1': 40,
    '0': 50,
    '1': 60,
    '2': 70, 
    '3': 80,
    '4': 90,
    '5': 100,
};

const decimal = {
    '0':0,
    '1':1,
    '2':2,
    '3':3,
    '4':4,
    '5':5,
    '6':6,
    '7':7,
    '8':8,
    '9':9,
}
 export const getScore = (score) => {
    let result = 0;
    if(score < 0) {
        result = Math.ceil(score);
        result = scoreMap[result] - decimal[getFirstNumberAfterDecimal(score)];
    } else {
        result = Math.floor(score);
        result = scoreMap[result] + decimal[getFirstNumberAfterDecimal(score)];
    }
    return result;
}



function getFirstNumberAfterDecimal(number) {
    // Convert the number to a string to easily manipulate it.
    const numberString = number.toString();

    // Use regular expressions to find the first digit after the decimal point.
    const match = numberString.match(/\.\d/);

    if (match) {
        // Extract and return the matched digit.
        return match[0].charAt(1);
    } else {
        // If there is no decimal point or no digit after it, return null or any other default value.
        return '0'; // or return a default value like -1, 0, etc.
    }
}




