import styled from "styled-components";

export const Progress = styled.ul`
    color: #4C4C4C;
    counter-reset: step;
    width: 950px;
    height: 60px;
    margin: auto;
    margin-bottom: 50px;

    & li {
        list-style-type: none;
        width: 32%;
        float: left;
        text-transform: uppercase;
        text-emphasis: 1;
        text-align: center;
        position: relative;
    }

    & li i {
        font-style:normal;
        width: 30px;
        height: 30px;
        content: counter(step);
        line-height: 30px;
        counter-increment: step;
        display: block;
        background-color: #fff;
        border: 2px solid #DDDDDD;
        border-radius: 50%;
        margin: 0 auto 10px auto;
    }

    & li:after {
        width: 100%;
        height: 4px;
        content: '';
        position: absolute;
        background-color: #DDDDDD;
        top: 15px;
        left: -50%;
        z-index: -1;
    }

    & li:first-child:after {
        content: none;
    }

    & li.current i {
        border: 5px solid;
        font-size: 20px;
    }

    & li i, li::after{
        -webkit-box-sizing: initial;
        -moz-box-sizing: border-box;
        box-sizing: content-box !important;
    }

    & li.active {
        color: #4C4C4C;
    }

    & li.active i {
        color: #ff4f1f;
        border-color: #ff4f1f;
        content: '\ea41'
        font-family: 'boxicons' !important
        font-weight: 900;
    }

    & li.active+li:after {
        background-color: #DDDDDD;
    }   

  
`