import { CircularProgress, Stack } from "@mui/material";



const Spinner = () => {
    return <Stack sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
    </Stack>
}


export default Spinner;