import { Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={< ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const imageBinder = (imagePath) => {
    return imagePath?.map(im => {
        return (
            <Box
                component="img"
                sx={{ display: "flex", border: "2px solid #afb0b1", borderRadius: 2, margin: "auto", width: "60%", marginTop: 2 }}
                src={im} />
        );
    });
}

export const contentBinder = (bodyContent) => {
    return bodyContent?.map((bc) => {
        return (
            <Typography sx={{ m: 2 }}>
                {bc}
            </Typography>
        );

    });
}

export const accordionHeader = (i, headerTitle) => {
    return <AccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-content`}>
        <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>{headerTitle?.title}</Typography>
    </AccordionSummary>;
}

export const listBinder = (list) => {
    return list?.lists && list?.listContentExplanation.map((lc, i) => {
        return (
            <>
                <Typography sx={{ fontWeight: "bold", fontSize: 15, m: 1, marginLeft: 2 }}>
                    {list.lists[i]}
                </Typography>
                <Typography sx={{ marginLeft: 5 }}>
                    {lc}
                </Typography>
            </>
        );
    });
}

export const stepsBinder = (steps) => {
    return <ul>
        {steps.map(data => {
            return (<li>{data}</li>)
        })}
    </ul>
}


export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`survey-tabpanel-${index}`}
            aria-labelledby={`survey-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const changeTabProps = (index) => {
    return {
        id: `survey-tab-${index}`,
        'aria-controls': `survey-tabpanel-${index}`,
    };
}

