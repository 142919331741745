import React, { useEffect, useState } from "react";
import Header from "../common/header/index";
import { MainDiv, ParentDiv, WizardContent } from '../styles';
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import ProgressBar from "../common/progressBar/index";
import Loader from "../common/loader";
import ProductStatus from "../common/productsStatus/productStatus";
import WizardApp from "../common/scripts/wizard-app";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getHeaders } from "../utilis";
import Spinner from "components/Spinner/Spinner";
import { Box } from "@mui/material";

const IndexView = (props) => {
    const gumStartApp = new WizardApp(config.appBaseUrl);
    const [productDetails, setProductDetails] = useState({ isStart: true, status: "", productStatus: "", statusIcon: "", btnIcon: "", msgContent: "", eventChange: () => { } })
    const [isLoading, setLoader] = useState(true);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState();
    useEffect(() => {
        let conversationId = new URLSearchParams(location.search).get('conversationId');
        if (conversationId != null) {
            sessionStorage.setItem('conversationId', conversationId);
            navigate('/main');
            return;
        }
        setProductDetails({
            isStart: true,
            status: "success", productStatus: "Product Available", statusIcon: "fas fa-check-circle", btnIcon: "fas fa-chevron-right",
            msgContent: "We're ready to set you up. Please click the Start button.",
        });
        gumStartApp.start()
            .then(() => {
                console.debug('Check if the product is installed either via PS/AF');
                getCustomerData();
            }).catch((err) => {
                setLoader(false)
                setProductDetails({
                    isStart: true,
                    status: "fail", productStatus: "Product could not be installed", statusIcon: "fas fa-exclamation-circle",
                    msgContent: "Error in installation.",
                })
                console.error('Error in index view: ' + err)
            })
    }, [])


    const getCustomerData = async () => {
        let orgId = sessionStorage.getItem("orgId");
        if (orgId) {
            let url = `${config.backendEndpoint}getOrg/${orgId}`;
            let customerData = await axios.get(url, getHeaders());
            setCustomerData(customerData);
            if (customerData?.data == undefined || customerData?.data == "") {
                console.debug('Check for product availability in AF');
                gumStartApp.validateProductAvailability()
                    .then((isAvailable) => {
                        if (!isAvailable) {
                            setProductDetails({
                                isStart: true,
                                status: "fail", productStatus: "Product not available", statusIcon: "fas fa-times-circle",
                                msgContent: "We're sorry but your Genesys Cloud org does not have the Real Time Transcription app enabled. Please contact the authorities.",
                            })
                        } else {
                            setProductDetails({
                                isStart: true,
                                status: "success", productStatus: "Product Available", statusIcon: "fas fa-check-circle", btnIcon: "fas fa-chevron-right",
                                msgContent: "We're ready to set you up. Please click the Start button.",
                            });
                        }
                        gumStartApp.getUserDetails()
                            .then((user) => {
                                if (user) {
                                    setUserName(user?.name)
                                    setLoader(false);
                                }
                            });

                    }).catch((err) => {
                        setLoader(false)
                        setProductDetails({
                            isStart: true,
                            status: "fail", productStatus: "Product could not be installed", statusIcon: "fas fa-exclamation-circle",
                            msgContent: "Error in installation.",
                        })
                        console.error('Error in index view: ' + err)
                    })
            } else if (customerData?.data != undefined || customerData?.data != "") {
                navigate('/main')
            }
        }
    }


    // useEffect(() => {
    //     gumStartApp.start()
    //         .then(() => {
    //             console.debug('Check if the product is installed either via PS/AF');
    //             getCustomerData();
    //         }).catch((err) => {
    //             setLoader(false)
    //             setProductDetails({
    //                 isStart: true,
    //                 status: "fail", productStatus: "Product could not be installed", statusIcon: "fas fa-exclamation-circle",
    //                 msgContent: "Error in installation.",
    //             })
    //             console.error('Error in index view: ' + err)
    //         })
    // }, []);


    return (
        <>
            {(isLoading == true) && (<Box sx={{ height: '100vh' }}><Spinner /></Box>)}
            {isLoading == false && <ParentDiv>
                <div id="background"></div>
                <Header tail="Start"></Header>
                <WizardContent>
                    <WelcomeGreet userName={userName} tabName="start"></WelcomeGreet>
                </WizardContent>

                {(isLoading == false && <>
                    <ProgressBar tabName="start"></ProgressBar>
                    <MainDiv>
                        <ProductStatus {...productDetails} eventChange={() => navigate('/install')}></ProductStatus>
                    </MainDiv>
                </>)}

            </ParentDiv>}
        </>


    )
}

export default IndexView;