import React, { useEffect, useState } from "react";
import { MainDiv, ParentDiv, WizardContent } from "../styles";
import Header from "../common/header";
import Loader from "../common/loader";
import ProductStatus from "../common/productsStatus/productStatus";
import ProgressBar from "../common/progressBar";
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import { useLocation } from "react-router-dom";

const SummaryView = () => {
    let [isLoading, setLoader] = useState(true);
    const [platformList, setPlatformList] = useState([]);
    const [productDetails, setProductDetails] = useState({ status: "", productStatus: "", statusIcon: "", btnIcon: "", msgContent: "" });
    const location = useLocation();
    const data = location.state;
    useEffect(() => {
        const appList = JSON.parse(window.localStorage.getItem("appList"));
        setPlatformList(appList);
        if (data && data.error) {
            setProductDetails({
                status: "fail", productStatus: "Product not installed successfully", statusIcon: "fas fa-times-circle",
                msgContent: `We have encountered an error while installing the Real Time Transcription`,
            });
        } else {
            setProductDetails({
                status: "success", productStatus: "Product installed", statusIcon: "fas fa-check-circle",
                msgContent: `The installation of the Real Time Transcription  application is now complete.`,
            });
        }
        setLoader(false);
    }, [])

    return (
        <ParentDiv>
            <div id="background"></div>
            <Header tail="Summary"></Header>

            <WizardContent>
                {(isLoading) &&
                    <Loader></Loader>
                }
                <WelcomeGreet tabTitle="Done"></WelcomeGreet>
            </WizardContent>

            <ProgressBar tabName="summary"></ProgressBar>

            <MainDiv>
                <ProductStatus {...productDetails}></ProductStatus>
                <div className="summary">
                    {platformList && platformList?.map((data, index) => {
                        return <p key={index} className="tobeinstall">{` ${index + 1}. ${data.tenantName}(${data.platformName})`}</p>
                    })
                    }
                </div>
            </MainDiv>
        </ParentDiv>
    )
}

export default SummaryView;