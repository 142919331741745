import { getStorageItem } from "./applicationStorage"
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Tooltip } from '@mui/material';

export const getParameterByName = (name) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.hash)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    const json = await response.json()
    return response.ok ? json : Promise.reject(json)
}

export const authorizeTheToken = async (token, env) => {
    if (token === null || token === '') {
        console.debug("Token from session storage is null");
        return false;
    }

    const platformClient = window.require('platformClient');
    var client = platformClient.ApiClient.instance;
    client.setEnvironment(env);
    client.setAccessToken(token);

    let apiInstance = new platformClient.TokensApi();

    let tokenResponse = await apiInstance.headTokensMe().then(() => {
        return true;
    })
        .catch((err) => {
            console.log('There was a failure calling headTokensMe. Clearing sessions');
            console.error(err);
            sessionStorage.removeItem("purecloud-csp-token");
            return false;
        });

    return tokenResponse;
}

var sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }
export const queuesList = []
export const queuesHashList = new Set();
export const divisionsList = new Set();
export let isAdminRole = false;

export const updateQeueus = (queues) => {
    
    queues.forEach(item => {
        if(!queuesHashList.has(item?.id)) {
            queuesHashList.add(item.id);
            queuesList.push.apply(queuesList, [item]);
            divisionsList.add.apply(divisionsList, [item?.division?.id]);
        }
        
    })
    // queuesList.push.apply(queuesList, queues)
}

export const checkIsJSON = (text) => {
    if (typeof text !== "string") {
        return false;
    }
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
}

export const formatDateAndTime = (isoDateString) => {
    const date = new Date(isoDateString);

    // Extract date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year} ; ${hours}:${minutes}`;

    return formattedDate;
}

export const getSentimentIcon = (analysisScore) => {
    const sentiment = getClassByScore(analysisScore);
    switch (sentiment) {
        case 'greenScore':
            return <Tooltip title={'Overall sentiment is positive'} placement="right" arrow><Box sx={{ backgroundColor: '#3C8527', borderRadius: '50%', marginLeft: '5px', padding: '2px 5px 5px 5px' }}>
                <ThumbUpAltIcon sx={{ color: 'white' }} fontSize='small'></ThumbUpAltIcon>
            </Box> </Tooltip>
        case 'yellowScore':
            return <Tooltip title={'Overall sentiment is neutral'} placement="right" arrow><RemoveCircleIcon sx={{ marginLeft: '5px' }} /></Tooltip>
        case 'redScore':
            return <Tooltip title={'Overall sentiment is negative'} placement="right" arrow><Box sx={{ backgroundColor: '#EA0B0B', borderRadius: '50%', marginLeft: '5px', padding: '2px 5px 5px 5px' }}>
                <ThumbDownAltIcon sx={{ color: 'white' }} />
            </Box> </Tooltip>

    }
}

export const getClassByScore = (score) => {
    if(score < 50) {
        return 'redScore';
    } else if(score >= 50 && score < 60) {
        return 'yellowScore';
    } else if(score >= 60 && score <=100){
        return 'greenScore'
    }
}

export const setAdminRole = () => {
    isAdminRole = true;
}
export const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}
export default {
    getParameterByName,
    fetchWrapper,
    authorizeTheToken,
    sleep,
    updateQeueus,
    formatDateAndTime,
    formatDate
}