import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        padding: '10px',
        background: '#e8ebf7',
        color: 'black'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '8px'
    }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#fffff",
    },
    // '& td':{
    //     borderRight: '1px solid #aaa'
    // },
}));

export const TableRowQueue = styled(TableRow)(({ theme }) => ({
    '& :hover': {
        backgroundColor: "lightgrey"
    },
    '&:nth-of-type(odd)': {
        backgroundColor: "#fffff",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

    '& .MuiTableCell-body': {
        "cursor":"pointer"
    }
}));

export const StyledSearch = styled(TextField)(({ theme }) => ({
    '&.MuiTextField-root': {
        'margin': '5px',
        'boxShadow': 'none',
        float: 'right',
        width: '20%',
      },
}));
