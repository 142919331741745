import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Configuration
import config from '../config';

// Util
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

// Route Components
const PureCloudLogin = lazy(() => import('components/login/purecloud/PureCloudLogin'));
// const PureConnectLogin = lazy(() => import('components/login/pureconnect/PureConnectLogin'));
const Home = lazy(() => import('components/home/Home'));
const Unauthorized = lazy(() => import('components/unauthorized/Unauthorized'));

// Don't lazy load initial application entry.
import Main from 'components/Main';
import Agent from 'components/agent/Agent';
import SupervisourView from 'components/supervisor/supervisor';
import FlaggedAgentView from 'components/flaggedAgents/flaggedAgents';
import BasicTabs from 'components/helpPage/UserGuide';
import Settings from 'components/settings/settings';
import IndexView from 'wizard/Index';
import InstallView from 'wizard/Install';
import SummaryView from 'wizard/summary';
import PopUpLogin from 'components/login/purecloud/PopUpLogin';

const ApplicationRouter = ({ }) => {
    // Login Provider Determined
    const LoginPage = PureCloudLogin;

    const LazyLoadedRoutesFallback = () => {
        return (
            <div>Loading...</div>
        )
    };
    return (
        <BrowserRouter>
            <Suspense fallback={LazyLoadedRoutesFallback()}>

                {/* <Route path="/" exact={true} element={<PublicRoute history={history} Component={IndexView}></PublicRoute>} />
                <Route path="/index" exact={true} element={<PublicRoute history={history} Component={IndexView}></PublicRoute>} />
                <Route path="/configuration" exact={true} element={<PublicRoute history={history} Component={Configuration}></PublicRoute>} />
                <Route path="/install" exact={true} element={<PublicRoute history={history} Component={InstallView}></PublicRoute>} />
                <Route path="/summary" exact={true} element={<PublicRoute history={history} Component={SummaryView}></PublicRoute>} /> */}



                <Routes>
                    <Route path="/" element={<IndexView />} />
                    <Route path="/wizardIndex" element={<IndexView />} />
                    <Route path="/install" element={<InstallView />} />
                    <Route path="/summary" element={<SummaryView />} />
                    <Route path="/help" element={<BasicTabs />} />
                    <Route path="/" element={<PublicRoute />} >
                        <Route path="/main" element={<Main />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                    </Route>
                    <Route path="/home" element={<PrivateRoute />}>
                        <Route path="" element={<Home />}>
                            <Route path="agents" element={<SupervisourView />} />
                            <Route path="flaggedagents" element={<FlaggedAgentView />} />
                            <Route path="help" element={<BasicTabs />} />
                            <Route path="settings" element={<Settings />} />
                        </Route>
                    </Route>
                    <Route path="/" element={<PublicRoute />}>
                        <Route path="/agentView" element={<Agent />} />
                    </Route>
                    <Route path="/" element={<PublicRoute />}>
                        <Route path="/popuplogin" element={<PopUpLogin />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default ApplicationRouter;