import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import HelpPage from './helpPage';
import Version from './releaseNote';
import { TabPanel, changeTabProps } from './common';

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Help Page" {...changeTabProps(0)} />
                    <Tab label="Release Note" {...changeTabProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <HelpPage />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Version />
            </TabPanel>
        </Box>
    );
}
