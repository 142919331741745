import './PureCloudLogin.css'
import React, { useEffect, useState, Fragment } from 'react'
import config from '../../../config'

const PopUpLogin = () => {

  const [msgData, setMsgData] = useState(false)

  function parseHash() {

    let authData = {};
    let hashAsObj = {};
    const hash = window.location.hash;
    const hashRegex = new RegExp(`^#*(.+?)=(.+?)$`, 'i');

    hash.split('&').forEach((h) => {
      const match = hashRegex.exec(h);
      if (match) {
        hashAsObj[match[1]] = decodeURIComponent(decodeURIComponent(match[2].replace(/\+/g, '%20')));
      }
    });

    /* if there is an error, return and don't attempt to parse the rest */
    if (hashAsObj.error) {
      return hashAsObj;
    }


    if (hashAsObj.access_token) {
      /* use the hash if we have one */
      if (hashAsObj.state) {
        authData.state = hashAsObj.state;
      }

      /* calculate expiry time */
      if (hashAsObj.expires_in) {
        const expiresInMs = (parseInt(hashAsObj.expires_in.replace(/\+/g, '%20')) * 1000);

        authData.tokenExpiryTime = Date.now() + expiresInMs;
        authData.tokenExpiryTimeString = (new Date(authData.tokenExpiryTime)).toISOString();
      }
      /* set the access token */
      authData.accessToken = hashAsObj.access_token.replace(/\+/g, '%20');
    }

    return authData;
  }

  useEffect(() => {

    var url = new URL(window.location.href);

    var env = url.searchParams.get("env");

    let authData = parseHash();

    let currentUrl = window.location.href
    let domain = currentUrl.split('/')[2]
    let protocol = currentUrl.split('/')[0]


    if (!authData || !authData?.hasOwnProperty('accessToken')) {
      setMsgData("Redirecting to GC Login")
   
      let clientId = config.clientId

      let redirectUri = protocol + '//' + domain + '/popuplogin'
      if (env) {
        window.location.href = `https://login.${env}/oauth/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}`
      }
    }
    else {
      setMsgData("Redirecting Auth Information")
      let data = JSON.stringify(authData)
      let type = 'purecloud-csp-auth'
      window.opener.postMessage({ data, type }, '*');
      window.close();

    }

  }, [])

  return (<Fragment>
    {msgData ? <div className="popup-login-msg">{msgData}</div> : null}
  </Fragment>)
}

export default PopUpLogin