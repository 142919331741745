import React, { useEffect, useState } from "react";
import { ChildDiv, MainDiv, ParentDiv, TitleDiv, WizardContent, Button, UlList } from "../styles";
import Header from "../common/header";
import Loader from "../common/loader";
import ProgressBar from "../common/progressBar";
import WizardApp from "../common/scripts/wizard-app";
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import { useNavigate } from "react-router-dom";
import { SpanDiv } from "wizard/Index/index-style";

const InstallView = (props) => {
    let [isLoading, setLoader] = useState(false);
    const startApp = new WizardApp();
    const navigate = useNavigate();


    const InstallConfiguration = () => {

        startApp.installConfigurations().then((done) => {
            setTimeout(() => {
                navigate('/summary');
            }, 12000);
        }).catch((err) => {
            console.error(err)
            if (err && err.body && err.body.message) {
                navigate('/summary', { state: { error: err.body.message } });
            } else if (err) {
                navigate('/summary', { state: { error: err } });
            } else {
                navigate('/summary');
            }
        });
    };

    return (
        <ParentDiv>
            <div id="background"></div>
            <Header tail="Install"></Header>

            <WizardContent>
                {(isLoading) &&
                    <Loader></Loader>
                }
                <WelcomeGreet tabTitle="Install"></WelcomeGreet>
            </WizardContent>

            <ProgressBar tabName="install"></ProgressBar>

            <MainDiv>
                <ChildDiv type="install">
                    <TitleDiv>Create Roles</TitleDiv>
                    <span>As part of this installation we will create roles with the sole purpose of granting access to this application.</span>
                    <span  ><b className="sjs-install-role">Real Time Transcription</b>   </span>
                    <UlList>
                        <li> Privileges to access the back end APIs</li>
                    </UlList>

                    <span ><b className="sjs-install-role">Real Time Transcription Admin</b></span>
                    <UlList>
                        <li> Privileges to Access end to end application.</li>
                        <li> Privileges to View the agent real-time transcript.</li>
                        <li> Privileges to Flag the agent.</li>
                    </UlList>


                    <span  ><b className="sjs-install-role">Real Time Transcription Supervisor</b>   </span>
                    <UlList>
                        <li> Privileges to View the agent real-time transcript.</li>
                        <li> Privileges to Flag the agent.</li>
                    </UlList>

                    <span ><b className="sjs-install-role">Real Time Transcription Agent</b></span>
                    <UlList>
                        <li> Privileges to View the real-time transcript.</li>
                    </UlList>

                </ChildDiv>
                <ChildDiv type="install">
                    <TitleDiv>Create OAuth Client</TitleDiv>
                    <span>Create OAuth Client for server-to-server communication.</span>
                </ChildDiv>
                <Button position="right" onClick={InstallConfiguration}>
                    <SpanDiv>Submit</SpanDiv>
                    <i className="fa-solid fa-circle-plus"></i>
                </Button>
            </MainDiv>
        </ParentDiv >
    )
}

export default InstallView;