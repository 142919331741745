import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { accordionHeader, imageBinder, listBinder, contentBinder, documentationModel, Accordion, AccordionDetails } from './common';
import { Box } from '@mui/material';
import releaseNote from './ReleaseNotes_en.json'

const Version = () => {
    const [markdown, setMarkdown] = useState(releaseNote);
    const [expanded, setExpanded] = useState('');


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    };

    return (
        <Box sx={{ marginTop: 2 }}>
            {markdown?.map((e, i) => {
                return (
                    <Accordion expanded={expanded === `parentPanel${i}`} onChange={handleChange(`parentPanel${i}`)} sx={{ marginRight: 2 }}>
                        {accordionHeader(i, e)}
                        <AccordionDetails>
                            {e?.content && e?.content.map((c) => {
                                return (
                                    <>
                                        <Typography sx={{ m: 2 }} >{c}</Typography>
                                    </>
                                )
                            })}
                            {e?.imagePath && imageBinder(e.imagePath)}
                            {listBinder(e)}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Box >
    );
}

export default Version;
