import React, { useEffect } from 'react'
import { getStorageItem } from 'services/applicationStorage';
import { useDispatch } from 'react-redux';
import {  addMessages } from 'redux/agent/agentSlice';
import Sentiment from 'sentiment';
import {  makeConversationInactive, updateAgentsNames } from 'redux/agent/agentActions';
import { InvalidToken } from 'common/Constants';
import { useNavigate } from 'react-router';
const sentiment = new Sentiment();

const platformClient = window.require('platformClient');
let client = platformClient.ApiClient.instance;

const WebSocketRTT = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        wss();

    }, [])

    async function wss() {
        client.setAccessToken(getStorageItem('purecloud-csp-token', true, sessionStorage));
        client.setEnvironment(getStorageItem('purecloud-csp-env', true, sessionStorage));
        //create Channel
        const napi = new platformClient.NotificationsApi();
        let channel = await napi.postNotificationsChannels()
        sessionStorage.setItem("gc_channelid", channel.id)

        //subscribe to topics
        let transcriptTopic = `v2.conversations.${sessionStorage.getItem('agent-current-interaction')}.transcription`
        let userActiveCallsTopic = `v2.users.${getStorageItem('userId', true, sessionStorage)}.conversations.calls`
        await napi.postNotificationsChannelSubscriptions(channel.id, [
            {
                id: transcriptTopic
            },
            {
                id: userActiveCallsTopic
            }]).then(() => {
                console.log(
                    'Subscribed to topic ' + `v2.conversations.${sessionStorage.getItem('agent-current-interaction')}.transcription`
                );
                console.log(
                    'Subscribed to topic ' + userActiveCallsTopic
                );

            }).catch(error => {
                console.error(error);
                if (error?.message === 'This method is not supported in a browser.' || (error?.status === 401 && error?.code === 'bad.credentials') || error?.message === InvalidToken) {
                    navigate('/login?redirectUrl=agentView');
                }
            });

        //create websocket
        try {
            let socket = new WebSocket(`wss://streaming.${getStorageItem('purecloud-csp-env', true, sessionStorage)}/channels/${sessionStorage.getItem("gc_channelid")}`)
            socket.onmessage = async function (event) {
                let details = JSON.parse((event?.data ?? {}));

                if (details?.topicName === `v2.conversations.${sessionStorage.getItem('agent-current-interaction')}.transcription`) {
                    const transcript = details.eventBody?.transcripts?.[0]?.alternatives[0]?.transcript
                    if (details.eventBody?.transcripts?.[0]?.channel === "EXTERNAL") {
                        // console.log('Customer: ' + details.eventBody.transcripts[0].alternatives[0].transcript);
                        dispatch(addMessages({
                            message: {
                                utteranceId: details.eventBody.transcripts[0].utteranceId,
                                purpose: "customer",
                                text: details.eventBody.transcripts[0].alternatives[0].transcript,
                                isRed: sentiment.analyze(transcript)?.comparative < 0,
                                sentimentScore: sentiment.analyze(transcript)?.comparative
                            }
                        }))
                    } else if (details.eventBody?.transcripts?.[0].channel === "INTERNAL") {
                        // console.log('Agent: ' + transcript);
                        dispatch(addMessages({
                            message: {
                                utteranceId: details.eventBody.transcripts[0].utteranceId,
                                purpose: "agent",
                                text: details.eventBody.transcripts[0].alternatives[0].transcript,
                                isRed: sentiment.analyze(transcript)?.comparative < 0,
                                sentimentScore: sentiment.analyze(transcript)?.comparative
                            }
                        }))
                    } else if (details.eventBody?.status?.status === 'SESSION_ENDED') {
                        // console.log("session has been ended");
                    }
                }

                if (details?.topicName === userActiveCallsTopic) {
                    const participants = details.eventBody.participants;
                    const convId = details.eventBody.id;

                    const state = participants.reverse().filter(item => item.purpose === 'agent' && item?.user?.id === getStorageItem('userId', true, sessionStorage))?.[0]?.state;
                    let agentsIds = participants.filter(item => item.purpose === 'agent').map(item => item?.user?.id);
                    agentsIds = new Set(agentsIds);
                    agentsIds = Array.from(agentsIds);
                    dispatch(updateAgentsNames(agentsIds))

                    if (state && convId === sessionStorage.getItem('agent-current-interaction')) {
                        if (state === 'disconnected' || state === 'terminated') {
                            const agents = participants.filter(item => item.purpose === 'agent');
                            const connectedAgents = agents.filter(item => item.state === 'connected').map(item => {
                                return item?.user?.id;
                            });
                            dispatch(makeConversationInactive({ connectedAgents }));
                        }
                    }

                }

            }
            
            socket.onclose = function(event) {
                console.log('WebSocket connection closed:', event);
                // Reconnect after a delay
                setTimeout(wss, 3000); 
              };
            
            socket.onerror = function(event) {
                console.error('WebSocket error:', event);
                // Handle errors and reconnect
                if (!socket || socket.readyState != WebSocket.OPEN) {
                    console.log('Attempting to reconnect...');
                    setTimeout(wss, 3000);
                    }
            };
            
        } catch (error) {
            sessionStorage.removeItem("gc_channelid")
            console.error(error);
            if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
                sessionStorage.setItem('conversationId', conversationId)
                window.location.href = config.appBaseUrl + '/login?redirectUrl=agentView';
            }
            throw error;
        }

    }


    return (
        <div>
        </div>
    )

};
export default WebSocketRTT