import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import './modalpopup.css';
import { SecondaryButton, StyledButton } from 'common/common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1rem'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paperScrollPaper': {
        // height: '30vh',
        // width: '30vw',
        // minWidth: '450px'
    },
    '& .MuiDialogTitle-root': {
        fontWeight: '800',
        color: 'white'
    }
}));

const ModalConfirmation = (props) => {
    return (
        <div>
            <BootstrapDialog
                onClose={props.onCancel}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', backgroundColor: '#34435e' }} id="customized-dialog-title">
                    Delete Confirmation
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <span>Are you certain you want to delete the chosen conversation?</span>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton onClick={props.deleteConversations}>
                        Delete
                    </SecondaryButton>
                    <StyledButton onClick={props.onCancel}>Cancel</StyledButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default ModalConfirmation;
